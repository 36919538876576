import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

// Styles
import { secondaryDark } from "../utils/colors";

const PrivacyMessage = styled.div`
	width: 100%;

	color: ${secondaryDark};

	margin: 14px 0 28px 0;

	& a {
		color: ${secondaryDark};
	}
`;

export const PrivacyPolicyCustomer = () => {
	const data = useStaticQuery(graphql`
		{
			prismicAccounts {
				data {
					customer_registration_privacy_policy {
						html
					}
				}
			}
		}
	`);

	return (
		<PrivacyMessage
			className="small-text"
			dangerouslySetInnerHTML={{
				__html:
					data.prismicAccounts.data.customer_registration_privacy_policy.html,
			}}
		/>
	);
};
