import React, { useState, useContext, useEffect } from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";

// Context
import StoreContext from "../../components/context/store";

// Components
import ConnexionLayout from "../../components/account/ConnexionLayout";
import { AccountSignupIntroduction } from "./account-signup-introduction";
import { TradeRegister } from "./register-trade";
import { CustomerRegister } from "./register-customer";
import {
	ContentContainer,
	Fields,
	RegisterFormHeader,
	FormSelectContainer,
} from "./login-components";

const CUSTOMER_LOGIN = gql`
	mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
		customerAccessTokenCreate(input: $input) {
			customerAccessToken {
				accessToken
				expiresAt
			}
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`;

const CUSTOMER_PASSWORD_RESET = gql`
	mutation customerRecover($email: String!) {
		customerRecover(email: $email) {
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`;

const RegisterForm = () => {
	const [registerFormType, setRegisterFormType] = useState("customer");

	return (
		<section className="account-registration">
			<RegisterFormHeader>
				<p>Account Registration</p>

				<FormSelectContainer>
					<button
						onClick={() => setRegisterFormType(`customer`)}
						className={registerFormType === `customer` ? `active` : `inactive`}
					>
						Customer
					</button>
					<button
						onClick={() => setRegisterFormType(`trade`)}
						className={registerFormType === `trade` ? `active` : `inactive`}
					>
						Trade
					</button>
				</FormSelectContainer>
			</RegisterFormHeader>

			{registerFormType === "customer" && <CustomerRegister />}
			{registerFormType === "trade" && <TradeRegister />}

			<form
				name="trade-registration"
				method="POST"
				data-netlify="true"
				data-netlify-honeypot="bot-field"
				hidden
			>
				<input type="hidden" name="bot-field" />
				<input type="hidden" name="form-name" name="trade-registration" />
				<input name="first_name" type="text" hidden />
				<input name="last_name" type="text" hidden />
				<input name="metafield_occupation" type="text" hidden />
				<input name="phone" type="tel" hidden />
				<input name="email" type="email" hidden />
				<input name="metafield_company_name" type="text" hidden />
				<input name="address1" type="text" hidden />
				<input name="city" type="text" hidden />
				<input name="zip" type="text" hidden />
				<input name="metafield_company_website" type="text" hidden />
				<input name="metafield_company_number" type="text" hidden />
				<input name="metafield_company_vat_number" type="text" hidden />
			</form>
		</section>
	);
};

const LoginForm = () => {
	const { setValue } = useContext(StoreContext);
	const [passwordForgot, setPasswordForgot] = useState(false);

	const [email, setEmail] = useState("");
	const [emailReset, setEmailReset] = useState("");

	const [messsageInfo, setMessageInfo] = useState("");

	const [password, setPassword] = useState(null);
	const handleCustomerAccessToken = (value) => {
		setValue(value);
	};

	const [loginErrorMessage, setLoginErrorMessage] = useState("");

	useEffect(() => {
		if (passwordForgot === true) {
			setMessageInfo("");
		}
	}, [passwordForgot]);

	return (
		<>
			{passwordForgot ? (
				<section className="forgot-password">
					<div>
						<div>
							<div>
								<div>
									<h1 className="">Reset Your Password</h1>
									<p className="password-reset">
										Please enter the e-mail address you used to log in and we
										will send you a link to reset your password.
									</p>
									<Mutation mutation={CUSTOMER_PASSWORD_RESET}>
										{(customerRecover) => {
											return (
												<Fields>
													<div className="field">
														<input
															className="input"
															type="email"
															id="recoverEmail"
															onChange={(e) => setEmailReset(e.target.value)}
															placeholder="Email"
														/>
													</div>

													<div className="field">
														<button
															className="reset-password-button"
															onClick={() => {
																customerRecover({
																	variables: {
																		email: emailReset,
																	},
																}).then(() => {
																	setMessageInfo(
																		"We've sent you an email with a link to update your password."
																	);
																});
															}}
														>
															Reset Password
														</button>
													</div>

													{messsageInfo && (
														<div className="password-reset-message">
															<p>{messsageInfo}</p>
														</div>
													)}

													<div className="field">
														<button
															className=""
															onClick={(e) =>
																setPasswordForgot(!passwordForgot)
															}
														>
															Return To Login
														</button>
													</div>
												</Fields>
											);
										}}
									</Mutation>
								</div>
							</div>
						</div>
					</div>
				</section>
			) : (
				<section className="account-login">
					<h1 className="">Account Login</h1>
					<Mutation mutation={CUSTOMER_LOGIN}>
						{(customerLogin) => {
							return (
								<Fields>
									<div className="field">
										<input
											className="input"
											type="email"
											id="loginEmail"
											onChange={(e) => setEmail(e.target.value)}
											placeholder="Email"
										/>

										<input
											className="input"
											type="password"
											id="loginPassword"
											onChange={(e) => setPassword(e.target.value)}
											placeholder="Password"
										/>
									</div>

									<div className="field">
										<button
											className="forgot-password small-text"
											onClick={(e) => setPasswordForgot(!passwordForgot)}
										>
											Forgot password
										</button>
									</div>

									<div className="field">
										<button
											onClick={() => {
												customerLogin({
													variables: {
														input: {
															email: email,
															password: password,
														},
													},
												})
													.then((result) => {
														if (
															result.data.customerAccessTokenCreate
																.customerAccessToken === null
														) {
															setLoginErrorMessage(
																"Login details not recognised"
															);
														}

														handleCustomerAccessToken(
															result.data.customerAccessTokenCreate
																.customerAccessToken
														);
													})
													.catch((err) => {
														alert(err);
													});
											}}
										>
											Login
										</button>
									</div>

									{loginErrorMessage && (
										<div className="field login-error-message">
											{loginErrorMessage}
										</div>
									)}
								</Fields>
							);
						}}
					</Mutation>
				</section>
			)}
		</>
	);
};

export const LoginOrRegister = () => (
	<ConnexionLayout>
		<ContentContainer>
			<AccountSignupIntroduction />
			<LoginForm />
			<RegisterForm />
		</ContentContainer>
	</ConnexionLayout>
);
