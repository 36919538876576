import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";

// Components
import { LoginOrRegister } from "../../components/account/login-or-register";

const Page = styled.div`
	margin: 100px 0 0 0;
	padding: 0 20px;

	@media (max-width: 768px) {
		margin: 65px 0 0 0;
	}
`;

const Login = () => (
	<Page>
		<Helmet title={`Account | Christian Watson`} />

		<LoginOrRegister />
	</Page>
);

export default Login;
