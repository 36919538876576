import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

// Styles
// import { secondaryLight } from "../utils/colors";

const AccountIntroduction = styled.div`
	width: 100%;
	max-width: 570px;

	margin: 0 0 70px 0;

	& p:first-of-type {
		margin-top: 0;
	}

	& p:last-of-type {
		margin-bottom: 0;
	}
`;

export const AccountSignupIntroduction = () => {
	const data = useStaticQuery(graphql`
		{
			prismicAccounts {
				data {
					account_text {
						html
					}
				}
			}
		}
	`);

	return (
		<AccountIntroduction
			dangerouslySetInnerHTML={{
				__html: data.prismicAccounts.data.account_text.html,
			}}
		/>
	);
};
