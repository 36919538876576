import React, { useState } from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";

// Components
import { PrivacyPolicyCustomer } from "./privacy-policy-customer";
import { Fields, SignupMessage } from "./login-components";

const CUSTOMER_REGISTER = gql`
	mutation customerCreate($input: CustomerCreateInput!) {
		customerCreate(input: $input) {
			customer {
				id
			}
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`;

export const CustomerRegister = () => {
	const [userSignupMessage, setUserSignupMessage] = useState("");
	const [userHasSubscribed, setUserHasSubscribed] = useState(false);
	const [state, setState] = useState({});
	const handleUserInput = (e) => {
		setState({ ...state, [e.target.name]: e.target.value });
	};

	return (
		<section>
			<Mutation mutation={CUSTOMER_REGISTER}>
				{(customerLogin) => {
					return (
						<Fields>
							{userHasSubscribed === false && (
								<>
									<div className="field">
										<input
											name="first_name"
											type="text"
											id="registerFirstName"
											onChange={handleUserInput}
											value={state.first_name}
											placeholder="First Name"
										/>

										<input
											name="last_name"
											type="text"
											id="registerLastName"
											onChange={handleUserInput}
											value={state.last_name}
											placeholder="Last Name"
										/>

										<input
											name="email"
											type="email"
											id="registerEmail"
											onChange={handleUserInput}
											value={state.email}
											placeholder="Email"
										/>

										<input
											name="password"
											type="password"
											id="registerPassword"
											onChange={handleUserInput}
											value={state.password}
											placeholder="Password"
											minLength="6"
											maxLength="40"
										/>
									</div>

									<PrivacyPolicyCustomer />

									<div className="field">
										<button
											onClick={() => {
												setUserHasSubscribed(true);
												customerLogin({
													variables: {
														input: {
															firstName: state.first_name,
															lastName: state.last_name,
															email: state.email,
															password: state.password,
															acceptsMarketing: true,
														},
													},
												}).then((result) => {
													if (
														result.data.customerCreate.customerUserErrors
															.length >= 1
													) {
														setUserSignupMessage(
															result.data.customerCreate.customerUserErrors[0]
																.message
														);
													} else {
														setUserSignupMessage(
															`Thank you for creating an account. An activation email has been sent to your email address.`
														);
														setState({
															first_name: "",
															last_name: "",
															email: "",
															password: "",
														});
													}
												});
											}}
										>
											Create Account
										</button>
									</div>

									{userSignupMessage !== "" && (
										<SignupMessage>
											<p>{userSignupMessage}</p>
										</SignupMessage>
									)}
								</>
							)}

							{userHasSubscribed && (
								<SignupMessage>
									<p>{userSignupMessage}</p>
								</SignupMessage>
							)}
						</Fields>
					);
				}}
			</Mutation>
		</section>
	);
};
