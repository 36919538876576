import React, { useState } from "react";
import { useForm } from "react-hook-form";

// Components
import { PrivacyPolicyTrade } from "./privacy-policy-trade";
import { Fields, SignupMessage } from "./login-components";

// Utils
import { encodeForm } from "../utils/encode-form";

async function createTradeCustomer(state) {
	const shopifyRegister = await fetch(`/api/create-trade-customer`, {
		method: "POST",
		body: JSON.stringify({
			data: state,
		}),
		headers: new Headers({
			"Content-Type": "application/json",
		}),
	});
	let getRegistrationResult = await shopifyRegister;
	return getRegistrationResult;
}

export const TradeRegister = () => {
	const [userSignupMessage, setUserSignupMessage] = useState("");
	const [userHasSubscribed, setUserHasSubscribed] = useState(false);
	const [isSubmissionInProgress, setIsSubmissionInProgress] = useState(false);

	// Form Setup
	const { register, handleSubmit, errors } = useForm();

	async function registerTradeCustomer(data, e) {
		e.preventDefault();
		setIsSubmissionInProgress(true);

		const createTradeCustomerResult = await createTradeCustomer(data);

		if (createTradeCustomerResult.status === 500) {
			setUserSignupMessage(
				"An error occurred while creating your account. Please email us directly at: info@christian-watson.com"
			);
			setIsSubmissionInProgress(false);
		} else {
			setUserSignupMessage(
				`Thank you for creating an account. An activation email has been sent to your email address.`
			);

			setUserHasSubscribed(true);

			fetch("/", {
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body: encodeForm({
					"form-name": "trade-registration",
					...data,
				}),
			});
		}
	}

	return (
		<section>
			<Fields>
				{userHasSubscribed === false && (
					<>
						<form
							name="trade-registration"
							method="POST"
							data-netlify="true"
							data-netlify-honeypot="bot-field"
							onSubmit={handleSubmit(registerTradeCustomer)}
						>
							<input type="hidden" name="bot-field" />
							<input type="hidden" name="form-name" name="trade-registration" />
							<div className="field">
								{/* errors will return when field validation fails  */}
								{errors.first_name && <span>This field is required</span>}
								<input
									name="first_name"
									type="text"
									id="registerFirstName"
									placeholder="First Name"
									ref={register({ required: true })}
								/>
							</div>

							<div className="field">
								{/* errors will return when field validation fails  */}
								{errors.last_name && <span>This field is required</span>}
								<input
									name="last_name"
									type="text"
									id="registerLastName"
									placeholder="Last Name"
									ref={register({ required: true })}
								/>
							</div>
							<div className="field">
								{/* errors will return when field validation fails  */}
								{errors.metafield_occupation && (
									<span>This field is required</span>
								)}
								<input
									name="metafield_occupation"
									type="text"
									id="registerOccupationIndustry"
									placeholder="Occupation / Industry"
									ref={register({ required: true })}
								/>
							</div>

							<br />
							<div className="field">
								{/* errors will return when field validation fails  */}
								{errors.phone && <span>This field is required</span>}
								<input
									name="phone"
									type="tel"
									id="registerTelephone"
									placeholder="Telephone"
									ref={register({ required: true })}
								/>
							</div>
							<div className="field">
								{/* errors will return when field validation fails  */}
								{errors.email && <span>This field is required</span>}
								<input
									name="email"
									type="email"
									id="registerEmail"
									placeholder="Email"
									ref={register({ required: true })}
								/>
							</div>

							<br />
							<div className="field">
								{/* errors will return when field validation fails  */}
								{errors.metafield_company_name && (
									<span>This field is required</span>
								)}
								<input
									name="metafield_company_name"
									type="text"
									id="registerCompanyName"
									placeholder="Company Name"
									ref={register({ required: true })}
								/>
							</div>
							<div className="field">
								{/* errors will return when field validation fails  */}
								{errors.address1 && <span>This field is required</span>}
								<input
									name="address1"
									type="text"
									id="registerCompanyAddress"
									placeholder="Company Address"
									ref={register({ required: true })}
								/>
							</div>

							<div className="field">
								{/* errors will return when field validation fails  */}
								{errors.city && <span>This field is required</span>}
								<input
									name="city"
									type="text"
									id="registerCompanyAddressCity"
									placeholder="Company City"
									ref={register({ required: true })}
								/>
							</div>

							<div className="field">
								{/* errors will return when field validation fails  */}
								{errors.zip && <span>This field is required</span>}
								<input
									name="zip"
									type="text"
									id="registerCompanyAddressZip"
									placeholder="Company Postal Code"
									ref={register({ required: true })}
								/>
							</div>

							<br />
							<div className="field">
								{/* errors will return when field validation fails  */}
								{errors.metafield_company_website && (
									<span>This field is required</span>
								)}
								<input
									name="metafield_company_website"
									type="text"
									id="registerCompanyWebsite"
									placeholder="Company Website"
									ref={register({ required: true })}
								/>
							</div>

							<div className="field">
								{/* errors will return when field validation fails  */}
								{errors.metafield_company_number && (
									<span>This field is required</span>
								)}
								<input
									name="metafield_company_number"
									type="text"
									id="registerCompanyNumber"
									placeholder="Company Number"
									ref={register({ required: true })}
								/>
							</div>

							<div className="field">
								{/* errors will return when field validation fails  */}
								{errors.metafield_company_vat_number && (
									<span>This field is required</span>
								)}
								<input
									name="metafield_company_vat_number"
									type="text"
									id="registerVATNumber"
									placeholder="VAT Number"
									ref={register({ required: true })}
								/>
							</div>

							<PrivacyPolicyTrade />

							<div className="field">
								<button
									type="submit"
									disabled={isSubmissionInProgress ? true : false}
								>
									Create Account
								</button>
							</div>
						</form>

						{userSignupMessage !== "" && (
							<SignupMessage>
								<p>{userSignupMessage}</p>
							</SignupMessage>
						)}
					</>
				)}

				{userHasSubscribed && (
					<SignupMessage>
						<p>{userSignupMessage}</p>
					</SignupMessage>
				)}
			</Fields>
		</section>
	);
};
